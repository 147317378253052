import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, ClickAwayListener, Fade, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import useStyles from './styles';

const Popover = ({
  placement,
  arrow,
  open,
  onClose,
  content,
  children,
  className,
  positionFixed,
  closable,
  btnContainerClass,
  anchorEl,
  autoOpen
}) => {
  const classes = useStyles({ placement });
  const [arrowRef, setArrowRef] = React.useState(null);
  const [childNode, setChildNode] = React.useState(anchorEl);
  const [isOpen, setIsOpen] = React.useState(open);

  React.useEffect(() => {
    if (!children) setChildNode(anchorEl);
  }, [anchorEl]);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div className={btnContainerClass}>
      {children
        ? React.cloneElement(children, {
            ...children.props,
            ref: setChildNode,
            onClick: autoOpen ? handleOpen : children.props.onClick
          })
        : null}
      <Popper
        open={isOpen}
        anchorEl={childNode}
        placement={placement}
        transition
        className={clsx(classes.popper, className)}
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'window'
          },
          arrow: {
            enabled: arrow,
            element: arrowRef
          }
        }}
        popperOptions={{ positionFixed }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.popoverRoot}>
                {closable && (
                  <Button
                    onClick={handleClose}
                    className={clsx(classes.closeButton, 'close-button')}
                  >
                    <ClearIcon />
                  </Button>
                )}
                {arrow ? <span className={clsx(classes.arrow, 'arrow')} ref={setArrowRef} /> : null}
                <Box className={classes.content}>{content}</Box>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

Popover.propTypes = {
  placement: PropTypes.string,
  className: PropTypes.string,
  btnContainerClass: PropTypes.string,
  arrow: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  children: PropTypes.node,
  positionFixed: PropTypes.bool,
  closable: PropTypes.bool,
  autoOpen: PropTypes.bool
};

Popover.defaultProps = {
  placement: 'bottom',
  className: null,
  btnContainerClass: null,
  arrow: true,
  open: false,
  onClose: () => {},
  content: null,
  positionFixed: true,
  closable: false,
  autoOpen: false
};

export default Popover;
