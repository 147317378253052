import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Typography, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  modalTextHeader: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: '40px',
    textAlign: 'center',
    color: '#525252',
    marginTop: 10,
    '@media only screen and (max-width: 425px)': {
      fontSize: 26,
      lineHeight: '20px'
    }
  },
  modalIconHeader: {
    fontSize: 25,
    marginRight: 5,
    position: 'relative',
    top: -2,
    '@media only screen and (max-width: 425px)': {
      fontSize: 20
    }
  },
  modalText: {
    marginBottom: 30,
    fontSize: 16,
    lineHeight: '28px',
    fontWeight: 400,
    maxWidth: 608,
    color: '#525252',
    textAlign: 'justify'
  },
  modalContentBox: {
    padding: '0 20px',
    '@media only screen and (max-width: 425px)': {
      padding: '0'
    }
  },
  modalHeader: {
    fontSize: 18,
    color: '#525252',
    padding: '0 20px',
    fontWeight: 500,
    textAlign: 'center'
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const ComingSoon = ({ forwardRef, onClose }) => {
  const classes = useStyles();

  const comingSoonRef = React.useRef(null);
  const [openComingSoon, setOpenComingSoon] = React.useState(false);

  React.useImperativeHandle(forwardRef, () => ({
    show: () => {
      setOpenComingSoon(true);
    },
    hide: () => {
      setOpenComingSoon(false);
    }
  }));

  const handleCloseComingSoon = () => {
    setOpenComingSoon(false);
    onClose();
  };

  return (
    <Dialog
      ref={comingSoonRef}
      onClose={handleCloseComingSoon}
      aria-labelledby="customized-dialog-title"
      open={openComingSoon}
    >
      <DialogTitle onClose={handleCloseComingSoon}>
        <Box className={classes.modalTextHeader}>
          <span role="img" aria-label="Gear" className={classes.modalIconHeader}>
            ⚙️
          </span>
          <span>Coming Soon</span>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.modalContentBox}>
          <Typography gutterBottom className={classes.modalText}>
            <span>
              Please don&apos;t be mad. We are working hard to fix this, the template will be
              available very soon!
            </span>
          </Typography>
          <Typography gutterBottom className={classes.modalText}>
            <span>Here&apos;s a joke to make you smile in the meantime:</span>
            <br />
            <span style={{ fontWeight: 600 }}>What’s the best thing about Switzerland?</span>
            <br />
            <em>I don’t know, but the flag is a big plus.</em>
            <span role="img" aria-label="smile">
              {' '}
              😊
            </span>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ComingSoon.propTypes = {
  forwardRef: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func
};

ComingSoon.defaultProps = {
  forwardRef: {},
  onClose: () => {}
};

export default ComingSoon;
