/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import translate from '../../data/translations';
import { isFunction } from '../../helpers/javascript';

const useStyles = makeStyles(theme => ({
  root: {
    display: ({ variant }) => (variant !== 'box' ? 'flex' : null),
    alignItems: ({ variant }) => (variant !== 'box' ? 'center' : null),
    justifyContent: ({ variant }) => (variant !== 'box' ? 'flex-start' : null),
    width: '100%',
    textAlign: ({ variant }) => (variant === 'box' ? 'center' : 'left'),
    color: ({ type, variant }) => {
      if (variant !== 'box') {
        if (type === 'success') return '#088400';
        if (type === 'info') return '#2196f3';
        if (type === 'warning') return '#ff9800';
        return '#f44336';
      }
      if (type === 'error') return '#000';
      return '#fff';
    },
    padding: ({ variant }) => (variant === 'text' ? 0 : 6),
    fontSize: 12,
    borderRadius: 6,
    backgroundColor: ({ type, variant }) => {
      if (variant === 'box') {
        if (type === 'success') return '#088400';
        if (type === 'info') return '#2196f3';
        if (type === 'warning') return '#ff9800';
        return '#FFCCCC';
      }
      return 'transparent';
    }
  },
  icon: {
    '& svg': {
      height: 16,
      width: 16,
      marginRight: 6
    }
  },
  link: {
    fontSize: 'inherit',
    margin: '0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

const ActionAlert = ({ type, message, className, variant, icon, modifiers }) => {
  const classes = useStyles({ type, variant });

  const params = message.match(/\{[a-zA-z-0-9.]+\}/g) || [];
  const messages = message.split(/\{[a-zA-z-0-9.]+\}/g);
  let j = 0;
  const m = [];
  for (let i = 0; i < messages.length; i += 1) {
    if (params[j]) {
      m.push(messages[i]);
      m.push(params[j]);
      j += 1;
    } else m.push(messages[i]);
  }

  return (
    <Box className={clsx(classes.root, className)}>
      {icon && <Box className={classes.icon}>{icon}</Box>}
      {m.map(mess => {
        const variable = mess.substring(1, mess.length - 1);
        if (modifiers[variable]) {
          return (
            <span
              className={isFunction(modifiers[variable]) ? classes.link : null}
              onClick={isFunction(modifiers[variable]) ? modifiers[variable] : () => {}}
            >
              {translate(variable, 'en')}
            </span>
          );
        }
        return <span key={mess}>{mess}</span>;
      })}
    </Box>
  );
};

ActionAlert.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info', 'warning']),
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['box', 'outline', 'text']),
  icon: PropTypes.node,
  modifiers: PropTypes.objectOf(PropTypes.any)
};

ActionAlert.defaultProps = {
  type: 'error',
  className: null,
  variant: 'box',
  icon: null,
  modifiers: {}
};

export default ActionAlert;
