import { Box, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'inline-grid',
    placeItems: 'center'
  },
  buttonProgress: {
    color: 'inherit',
    gridColumn: '1',
    gridRow: '1'
  },
  children: {
    gridColumn: '1',
    gridRow: '1'
  },
  hidden: {
    visibility: 'hidden'
  }
});

// eslint-disable-next-line react/prop-types
export function MultiStateButton({ isLoading, children, ...rest }) {
  const classes = useStyles();

  return (
    <Button {...rest}>
      <Box component="span" className={classes.root}>
        <Box className={clsx(classes.children, isLoading && classes.hidden)}>{children}</Box>
        {isLoading && <CircularProgress size={18} className={classes.buttonProgress} />}
      </Box>
    </Button>
  );
}
