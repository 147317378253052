/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Router from 'next/router';
import { Box, Button } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CustomDialog from '../Dialog';
import { API_HOST } from '../../services/app.config';
import { GoogleIcon, FacebookIcon } from '../Icon';
import { auth, sendRecoveryCode } from '../../services/security.service';
import { useAuthentication } from '../../hooks/useAuthentication';
import ActionAlert from '../Alert';
import { gtmFireEvent } from '../../helpers/gtm';
import UserProfessionJob from '../UserProfessionJob';
import { isFunction, isObject } from '../../helpers/javascript';
import useStyles from './styles';
import { useLocalStorage } from '../../helpers/browser';
import { validateUsername } from '../../helpers/schemaValidation';
import { MultiStateButton } from '../MultiStateButton';

const LogIn = ({
  forwardRef,
  onSignUp,
  showModal,
  showSignUp,
  onShowLoader,
  onHideLoader,
  onLogin,
  showGoogle,
  showFacebook,
  defaultEmail,
  showEmailReadonly,
  tag,
  size
}) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState(defaultEmail || '');
  const [password, setPassword] = React.useState('');
  const [globalError, setGlobalError] = React.useState();
  const [successError, setSuccessError] = React.useState();
  const [passwordError, setPasswordError] = React.useState(null);
  const [userError, setUserError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [isValidUsername, setIsValidUsername] = React.useState(defaultEmail ? true : null);
  const iframeRef = React.useRef(null);
  const dialogRef = React.useRef(null);
  const callbackRef = React.useRef();
  const pendingUser = React.useRef(null);
  const { dispatch } = useAuthentication();
  const emailField = React.useRef(null);
  const passwordField = React.useRef(null);

  const handleEscape = () => {
    if (dialogRef && dialogRef.current) dialogRef.current.hide();
    setIsValidUsername(true);
  };

  const logged = payload => {
    window.dataLayer.push({
      event: 'user',
      email: payload.user.email,
      name:
        payload.user && payload.user.profile && payload.user.profile.name
          ? payload.user.profile.name
          : payload.user.username
    });

    dispatch({ type: 'UPDATE_PAYMENT', plan: payload.plan || false });
    localStorage.setItem('user', JSON.stringify(payload.user));
    if (showModal) handleEscape();
    if (
      payload &&
      payload.user &&
      payload.user.callback &&
      payload.user.callback.action === 'register'
    ) {
      const audience = localStorage.getItem('user-audience');
      gtmFireEvent({
        event: 'onSecurity',
        category: audience === 'video' ? 'video-maker' : 'widgets',
        action: `register-${audience}`,
        label: `${payload.user.callback.provider}-register`
      });
      gtmFireEvent({
        event: 'onSecurity',
        category: 'security',
        action: 'register',
        label: `${payload.user.callback.provider}-register`
      });
    }
    dispatch({ type: 'LOGIN' });
    if (isFunction(callbackRef.current)) callbackRef.current(payload);
    callbackRef.current = null;
    onLogin();
  };

  const handleLogin = e => {
    const {
      data: { event, payload }
    } = e;
    if (event === 'on-woxo-login-success') {
      setProfile(
        isObject(payload.user.profile)
          ? {
              ...payload.user.profile,
              jwt: payload.user.jwt,
              team: payload.user.team,
              username: payload.user.username,
              email: payload.user.email
            }
          : {
              jwt: payload.user.jwt,
              team: payload.user.team,
              username: payload.user.username,
              email: payload.user.email
            }
      );
      pendingUser.current = payload.user;
      if (
        payload.user.profile &&
        payload.user.profile.profession &&
        payload.user.profile.entityType
      )
        logged(payload);
    } else if (event === 'on-woxo-login-fail') {
      const { error, user } = payload;
      if (user) pendingUser.current = user;
      setGlobalError(error);
    }
  };

  React.useImperativeHandle(forwardRef, () => ({
    show: fn => {
      setPassword('');
      setEmail('');
      setSuccessError('');
      callbackRef.current = fn;
      onHideLoader();
      setIsLoading(false);
      setProfile(null);
      setGlobalError(false);
      window.addEventListener('message', handleLogin);
      dialogRef.current.show();
    },
    hide: () => {
      window.removeEventListener('message', handleLogin);
      dialogRef.current.hide();
    }
  }));

  React.useEffect(() => {
    if (!showModal) window.addEventListener('message', handleLogin);
    let interval = setInterval(() => {
      if (emailField.current && passwordField.current) {
        if (emailField.current.value) {
          setIsValidUsername(true);
          setEmail(emailField.current.value);
        }
        if (passwordField.current.value) setPassword(passwordField.current.value);
        clearInterval(interval);
      }
    }, 100);

    return () => {
      window.removeEventListener('message', handleLogin);
    };
  }, []);

  const handleConnect = connector => {
    setGlobalError('');
    onShowLoader();
    setIsLoading(true);
    const width = 600;
    const height = 700;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    iframeRef.current = window.open(
      `${API_HOST}/connect/${connector}`,
      'Login',
      `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},dialog=yes,top=${top},left=${left}`
    );
    const timer = setInterval(() => {
      if (iframeRef.current.closed) {
        clearInterval(timer);
        onHideLoader();
        setIsLoading(false);
      }
    }, 1000);
  };

  const handleEmail = e => {
    const newEmail = e.target.value;
    if (!email) {
      handleValidEmail(newEmail);
    }
    setEmail(newEmail);
  };

  const handlePassword = e => setPassword(e.target.value);

  const logIn = event => {
    event.preventDefault();
    if (!password || !email || !email.trim() || !isValidUsername) {
      if (!password) setPasswordError('Password cannot be empty');
      if (!email || !email.trim())
        setUserError(`${tag === 'sumoling' ? 'E-mail' : 'E-mail or username'} cannot be empty`);
      else if (!isValidUsername)
        setUserError(`${tag === 'sumoling' ? 'E-mail' : 'E-mail or username'} is not valid`);
    } else if (!isLoading) {
      setPasswordError(null);
      setUserError(null);
      setGlobalError('');
      onShowLoader();
      setIsLoading(true);

      auth({
        identifier: email,
        password: password
      })
        .then(({ user, plan }) => {
          setEmail('');
          setPassword('');
          onHideLoader();
          setIsLoading(false);
          setProfile(
            isObject(user.profile)
              ? {
                  ...user.profile,
                  jwt: user.jwt,
                  team: user.team,
                  username: user.username,
                  email: user.email
                }
              : { jwt: user.jwt, team: user.team, username: user.username, email: user.email }
          );
          if (user.profile && user.profile.profession && user.profile.entityType)
            logged({ user, plan });
          else {
            pendingUser.current = user;
          }
        })
        .catch(e => {
          const { user } = e;
          if (user) pendingUser.current = user;
          setGlobalError(e.message);
          onHideLoader();
          setIsLoading(false);
        });
    }
  };

  const handleValidEmail = emailToCheck => {
    setIsValidUsername(validateUsername(emailToCheck));
  };

  const handleActivate = () => {
    onShowLoader();
    setIsLoading(true);
    sendRecoveryCode({
      headers: {
        Authorization: `Bearer ${pendingUser.current.jwt}`,
        'X-Mission-Control-Team': pendingUser.current.team
      }
    })
      .then(() => {
        onHideLoader();
        setIsLoading(false);
        setSuccessError('You have received an email to recover your account. Please check it!');
      })
      .catch(() => {
        onHideLoader();
        setIsLoading(false);
        setGlobalError('Error occurred during enabling your account. Please {messages.try.again}');
      });
  };

  const handleUpdateProfile = ({ profession, entityType, name }) => {
    setProfile(prevState =>
      isObject(prevState)
        ? { ...prevState, profession, entityType, name }
        : { profession, entityType, name }
    );
    logged({ user: { ...pendingUser.current, profile: { profession, entityType, name } } });
    handleEscape();
  };

  const resetPassword = () => {
    window.scrollTo(0, 0);
    Router.push('/forgot-password');
  };

  const loginContainer = () => {
    return (
      <Box
        className={clsx(
          classes.container,
          'login-container',
          size === 'medium' ? classes.container__medium : ''
        )}
      >
        {profile !== null &&
          (!profile || !profile.profession || !profile.entityType || !profile.name) && (
            <UserProfessionJob {...profile} onChange={handleUpdateProfile} />
          )}
        {profile === null && (
          <>
            <Box className={classes.title} textAlign="center">
              Log in
            </Box>
            <Box
              className={clsx(
                classes.subTitle,
                showGoogle || showFacebook ? '' : classes.paddingBottom
              )}
              textAlign="center"
            >
              with your account
            </Box>
            {(globalError || successError) && (
              <ActionAlert
                type={successError ? 'success' : 'error'}
                message={successError || globalError}
                modifiers={{
                  'login.up.link.sign': onSignUp,
                  'sign.up.link.login': true,
                  'message.here': handleActivate
                }}
                className={classes.alert}
              />
            )}
            {showGoogle && (
              <Box className={classes.socialContainer}>
                <Button className={classes.socialButton} onClick={() => handleConnect('google')}>
                  <GoogleIcon />
                  Connect with Google
                </Button>
              </Box>
            )}
            {showFacebook && (
              <Box className={classes.socialContainer}>
                <Button className={classes.socialButton} onClick={() => handleConnect('facebook')}>
                  <FacebookIcon />
                  Connect with Facebook
                </Button>
              </Box>
            )}
            {(showGoogle || showFacebook) && <Box className={classes.divider}>or</Box>}
            <form className={clsx(classes.form, 'login-form')}>
              <Box className={classes.formControl}>
                <label className={classes.label} htmlFor="#signup-email">
                  <Box className={classes.formTitle}>
                    {tag === 'sumoling' ? <span>E-mail</span> : <span>E-mail or username</span>}
                  </Box>
                  <input
                    ref={emailField}
                    className={clsx(classes.formInput, userError ? 'error' : null)}
                    id="signup-email"
                    onChange={handleEmail}
                    placeholder="jane@example.com or jane.doe"
                    onBlur={() => handleValidEmail(email)}
                    defaultValue={defaultEmail}
                    readOnly={showEmailReadonly || false}
                  />
                  {userError && (
                    <ActionAlert
                      icon={<ReportProblemOutlinedIcon />}
                      variant="text"
                      message={userError}
                      className={classes.formAlert}
                    />
                  )}
                </label>
              </Box>
              <Box className={classes.formControl}>
                <label className={classes.label} htmlFor="#signup-password">
                  <Box className={classes.formTitle}>Password</Box>
                  <input
                    ref={passwordField}
                    className={clsx(classes.formInput, passwordError ? 'error' : null)}
                    type="password"
                    id="signup-password"
                    onChange={handlePassword}
                    placeholder="*****"
                  />
                  {passwordError && (
                    <ActionAlert
                      icon={<ReportProblemOutlinedIcon />}
                      variant="text"
                      message={passwordError}
                      className={classes.formAlert}
                    />
                  )}
                </label>
              </Box>
              <Box className={classes.socialContainer}>
                <MultiStateButton
                  className={clsx(classes.socialButton, 'form-button')}
                  onClick={logIn}
                  isLoading={isLoading}
                  type="submit"
                >
                  Log in
                </MultiStateButton>
              </Box>
            </form>
            {showSignUp && (
              <Box className={classes.login}>
                Don't have an account yet?
                <Button className={classes.loginButton} onClick={onSignUp}>
                  Sign up
                </Button>
              </Box>
            )}
            <Box
              className={clsx(classes.forgot, 'login-forgot', !showSignUp ? classes.marginTop : '')}
            >
              <Button className={classes.linkButton} onClick={resetPassword}>
                I forgot my password
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return showModal ? (
    <CustomDialog
      forwardRef={dialogRef}
      fullWidth
      className={classes.root}
      maxWidth="md"
      onEscapeKeyDown={handleEscape}
      useClose
      isLoading={isLoading}
      loaderBackgroundColor="rgba(255, 255, 255, 15%)"
    >
      {loginContainer()}
    </CustomDialog>
  ) : (
    loginContainer()
  );
};

LogIn.propTypes = {
  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.objectOf(PropTypes.any)]),
  onSignUp: PropTypes.func,
  showModal: PropTypes.bool,
  showSignUp: PropTypes.bool,
  onShowLoader: PropTypes.func,
  onHideLoader: PropTypes.func,
  onLogin: PropTypes.func,
  showGoogle: PropTypes.bool,
  showFacebook: PropTypes.bool,
  defaultEmail: PropTypes.string,
  showEmailReadonly: PropTypes.bool,
  tag: PropTypes.string,
  size: PropTypes.string
};

LogIn.defaultProps = {
  forwardRef: () => {},
  onSignUp: () => {},
  showModal: true,
  showSignUp: true,
  onShowLoader: () => {},
  onHideLoader: () => {},
  onLogin: () => {},
  showGoogle: true,
  showFacebook: true,
  defaultEmail: '',
  showEmailReadonly: false,
  tag: '',
  size: 'large'
};

export default LogIn;
