import querystring from 'querystring';
import axios from './axios';
import logger from 'log-level';
import { isArray } from '../helpers/javascript';

export const find = ({ url, method, headers = {}, query }) =>
  new Promise((resolve, reject) => {
    if (!url) {
      logger.error('Url must not be empty');
      reject();
    }
    if (!method) {
      logger.error('Method must not be empty');
      reject();
    }
    let queryParams = '';
    if (query && typeof query === 'object' && query.constructor === Object)
      queryParams = `?${querystring.stringify(query)}`;
    axios({
      url: `${url}${queryParams}`,
      method,
      headers
    })
      .then(response => {
        if (isArray(response.data)) resolve(response.data);
        else {
          logger.error('Elements must be an array');
          reject();
        }
      })
      .catch(() => {
        logger.error('Ups fail to load elements');
        reject();
      });
  });
